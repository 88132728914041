@import "./styles/variables";

html, body {
  height: 100%;
  font-family: Arial, Helvetica, sans-serif
}

@font-face {
  font-family: 'Graphik';
  src: url(/home/runner/work/rezonence/rezonence/bazel-root/201956035e49410d535cb2daf92de3a0/execroot/rezonence/bazel-out/k8-fastbuild/bin/live-report/src/./assets/fonts/Graphik-Regular.woff2) format("truetype");
  font-weight: 400;
}

html, body * {
  font-family: Graphik,Helvetica,Arial,sans-serif;
}

span.active {
  background-color: gray;
}

.navbar-brand {
  margin-top: 10px;
  margin-right: 15px;
  padding: 0;
  height: 32px;

}

body {
  font-family: Ubuntu, serif;
}

h1, h2 {
  font-weight: 300;
  font-size: 25px;
}

.navbar-brand img {
  height: 32px;
  width: 32px;
  background-size: 32px 32px;

}

.vertical-center {
  display: flex;
  align-items: center;
}

h2 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.navbar {
  background-color: rgba(144, 195, 244, 0.12);
  border: none;
}

/*
    @media (min-width: 992px) {
      .container .row {
        display: flex;
        align-items:  center;
      }
    }*/

.jumbotron {
  padding-top: 0;
  padding-bottom: 0;
  background-color: white;
}

body {
  background-color: white;
}

pre {
  background-color: $info-color;
}


.form-control {
  box-shadow: none;
  border: none;
  padding: 6px 0;
}

.modal-content {

  margin-top: 50vh;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  border-radius: 2px;
  width: 100%;
  max-width: 450px;
  position: relative;
  box-sizing: border-box;

}

.modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}

.report-data {
  margin-top: 20px;
}

.modal-open {
  overflow: auto;

}

.modal-footer {
  border-top: 0;
}

.modal-header {
  border-bottom: 0;
}

.axis path.domain {

  fill: none;
  stroke: black;
  stroke-width: 3px;
}

.report-data {
  overflow-x: scroll;
}

.navbar {
  margin-bottom: 0;
}

.jumbotron {
  margin-bottom: 0;
}

button.mat-raised-button, button.mat-flat-button, a.mat-stroked-button, a.mat-raised-button, a.mat-flat-button, a.mat-stroked-button {
  border-radius: 3vmin;
}

[hidden] {
  display: none;
}

.progress-container p {
  font-size: 1.5rem;
}

.container-fluid {
  max-width: 1500px;
}
